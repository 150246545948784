















// CORE
import { Component } from 'vue-property-decorator'

// COMPONENTS
import AuthForm from '@/components/forms/AuthForm.vue'
import Card from '@/components/cards/Card.vue'
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    AuthForm,
    Card,
    DrawerHeader,
  },
})
export default class Login extends NotifyMixin {
  private created () {
    if (this.$route.params.redirectReason === '401') {
      this.notifyError('Для продолжения работы необходимо авторизоваться')
    }
  }
}
