











// CORE
import { Component, Mixins, Prop } from 'vue-property-decorator'

// MIXINS
import ThemeMixin from '@/mixins/ThemeMixin'

@Component
export default class DrawerHeader extends Mixins(ThemeMixin) {
  @Prop({ default: null })
  readonly heading!: string

  @Prop({ default: true })
  readonly isBackButton!: boolean
}
