// CORE
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

// STORE MODULES
import ProfileModule from './profile'

// INTERFACES
import { INewPassword, IMessage } from '@/store/types'

import {
  // REQUESTS
  AuthRegisterFleetPostRequest,
  AuthRegisterDriverPostRequest,
  AuthRegisterMechanicPostRequest,
  AuthResetPasswordPutRequest,
  AuthResetPasswordVerifyTokenGetRequest,
  AuthLoginPostRequest,
  AuthResetPasswordPostRequest,
  AuthLogoutPostRequest,
  MeGetRequest,
  // TYPES
  LoginRequestBody,
  RegisterFleetManagerRequest,
  RegisterCarDriverRequest,
  RegisterCarMechanicRequest,
} from '@/store/types/schema'

/**
 * Работа с авторизацией пользователя
 * - авторизация пользователя
 * - регистрация пользователя
 * - забыл пароль
 * - подтверждения регистрации и смены пароля
 * - получение пользователя
 */

@Module({
  dynamic: true,
  name: 'auth',
  namespaced: true,
  store,
})
class Auth extends VuexModule {
  // ---------------------------- AUTH ---------------------------- >>
  @Action({ rawError: true })
  async login (payload: LoginRequestBody) {
    const { data, status } = await AuthLoginPostRequest({
      email: payload.email.trim(),
      password: payload.password.trim(),
      remember: false,
    })

    if (status === 200) {
      ProfileModule.handleUserResource(data)
    }
  }

  @Action({ rawError: true })
  async logOut () {
    await AuthLogoutPostRequest()
      .then(() => {
        ProfileModule.clearUserData()
      })
  }

  // ---------------------------- User ---------------------------- >>

  @Action({ rawError: true })
  async getUserResource () {
    const { data } = await MeGetRequest()

    ProfileModule.handleUserResource(data)
    return data
  }

  // ---------------------------- REGISTRATION ---------------------------- >>

  roleType = 'driver'

  @Mutation
  setRole (payload: string) {
    this.roleType = payload
  }

  @Action({ rawError: true })
  async sendRegisterForm (form: RegisterCarDriverRequest | RegisterFleetManagerRequest) {
    if (this.roleType === 'fleet') {
      const { data } = await AuthRegisterFleetPostRequest(form as RegisterFleetManagerRequest)

      ProfileModule.handleUserResource(data)

      return data
    } else if (this.roleType === 'driver') {
      const { data } = await AuthRegisterDriverPostRequest(form as RegisterCarDriverRequest)

      ProfileModule.handleUserResource(data)

      return data
    } else {
      const { data } = await AuthRegisterMechanicPostRequest(form as RegisterCarMechanicRequest)

      ProfileModule.handleUserResource(data)

      return data
    }
  }

  // ---------------------------- RESET-PASSWORD ---------------------------- >>

  resetPasswordToken = ''

  @Mutation
  setResetPasswordToken (token: string) {
    Vue.set(this, 'resetPasswordToken', token)
  }

  // Отправка e-mail на бэк для отправки письма пользователю
  @Action({ rawError: true })
  async beginResetPassword (email: string) {
    const { status } = await AuthResetPasswordPostRequest({ email })

    if (status === 204) {
      return {
        success: true,
        message: 'Успешно',
      } as IMessage
    } else {
      return {
        success: false,
        message: 'Не успешно',
      } as IMessage
    }
  }


  // Отправка токена в стор из локального состояния компонента-формы
  @Action({ rawError: true })
  async verifyResetPasswordToken (token: string) {
    const { status } = await AuthResetPasswordVerifyTokenGetRequest({ token })
      .then(res => {
        return res
      })

    if (status === 204) {
      this.setResetPasswordToken(token) // Запись токена в стор при успешной верификации

      // возвращаем ответ в компонент во всех случаях
      return {
        success: true,
        message: 'Успешно',
      } as IMessage
    } else if (status === 403) {
      return {
        success: false,
        message: 'Время жизни токена истекло или у токена нет нужных разрешений',
      }
    } else {
      return {
        success: false,
        message: 'Неверный токен',
      }
    }
  }

  /**
   * Проверяем токен, ранее записанный в стор, в случае удачи
   * отправляем запрос на смену пароля в случае успеха записываем пользователя в стор
   * в случае обработанной ошибки возвращаем сообщение в компонент
   */
  @Action({ rawError: true })
  async resetPassword (form: INewPassword) {
    try {
      const { success } = await this.verifyResetPasswordToken(this.resetPasswordToken)

      if ( success ) {
        const { data, status } = await AuthResetPasswordPutRequest({
          password: form.password,
          passwordConfirmation: form.passwordConfirmation,
          token: this.resetPasswordToken,
          remember: false,
        })

        if (status === 204) {
          ProfileModule.handleUserResource(data)

          return {
            success: true,
            message: 'Успешно',
          }
        } else if (status === 403) {
          return {
            success: false,
            message: 'Время жизни токена истекло или у токена нет нужных разрешений',
          }
        } else {
          return {
            success: false,
            message: 'Неверный токен',
          }
        }
      }
    } catch (error) {
      return error
    }
  }
}

const AuthModule = getModule(Auth)

export default AuthModule
