






















































// CORE
import { Component, Mixins } from 'vue-property-decorator'
import { recaptchaKey } from '@/utils/services/config'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// LIBRARIES
import { required } from 'vee-validate/dist/rules'

// COMPONENTS
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { VueRecaptcha } from 'vue-recaptcha'

// TYPES
import { LoginRequestBody } from '@/store/types'
import AuthModule from '@/store/modules/auth'

extend('required', {
  ...required,
  message: 'Это обязательное поле',
})

extend('engOnlyEmail', {
  message: 'Введите действительный e-mail',
  validate: (value: string) => {
    const regExpEngEmail = /^(([a-z_A-Z\-0-9^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !!value.toString().match(regExpEngEmail)
  },
})

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
})
export default class AuthForm extends Mixins(NotifyMixin) {
  private regExpPhone = /^\+?[78]\s[-(]?\d{3}\)?-?[\s]?\d{3}-?\d{2}-?\d{2}$/
  private regExpPhoneWithCode = /^\+?[78]\s[-(]?\d{3}\)?-?[\s]?\d{3}-?\d{2}-?\d{2}\s-\s\d{4}$/

  private showPassword = false
  private errorCount = 0

  private recaptchaKey = recaptchaKey

  private get isShowCapcha (): boolean {
    return this.errorCount >= 2
  }

  protected form: LoginRequestBody = {
    email: '',
    password: '',
    remember: false,
  }

  private handleShowPassword () {
    this.showPassword = !this.showPassword
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.login()
        }
      })
  }

  private login () {
    AuthModule.login(this.form)
      .then(() => { this.$router.push({ name: 'role' }) })
      .catch(error => {
        this.notifyError(error)
        this.errorCount++
      })
  }
}
